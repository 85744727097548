<template>
  <div class="checkbox-form">
    <div class="answers">
      <div v-for="(option, i) of options" :key="i" class=" item">
        <label>
          <span :for="option" class="text">{{ option.label }}</span>
          <input
            :id="option.key"
            v-model="checked"
            type="checkbox"
            :value="option.key"
            @change="onChange"
          />
          <span :class="getCheckmarkClass(option)" />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "checkbox",
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectAll: {
      type: String,
      required: false,
      default: () => "7",
    },
  },
  data() {
    return {
      checked: [],
    };
  },
  methods: {
    onChange(e) {
      const selected = e.target.value;
      if (selected == this.selectAll && this.checked.includes(this.selectAll)) {
        this.options.forEach((data) => {
          if (!this.checked.includes(data.key)) {
            this.checked.push(data.key);
          }
        });
      }

      let result = 0;
      this.checked.forEach((data) => {
        result = result | parseInt(data);
      });
      this.$emit("input", result);
    },
    getCheckmarkClass(item) {
      return "checkmark " + item.class;
    },
  },
};
</script>

<style lang="postcss" scoped>
.checkbox-form {
  /* margin-top: 20px; */
  /* display: flex;
  flex-direction: column; */
  /* align-items: center;
  width: 100%; */
}

.checkbox-form .answers {
  /* display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%; */
  margin: auto;
}

.checkbox-form label {
  /* margin-left: -14px; */
}

.checkbox-form .item {
  display: block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 1em;
  height: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}



.checkbox-form .item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-form .item .text {
  color: #8ad5b2;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
}

.checkbox-form .checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  height: 13px;
  width: 13px;
  margin-top: auto;
  /* background-color: white; */
  border: 1px solid #4bebff;
}

.checkbox-form .item:hover input ~ .checkmark {
  /* background-color: #949494; */
}

.checkbox-form .item input:checked ~ .checkmark {
  /* background-color: #d8a22e; */
}

.checkbox-form .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-form .item input:checked ~ .checkmark:after {
  /* display: block; */
}

.checkbox-form .item .checkmark:after {
  /* left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); */
}

@media screen and (max-width: 1281px) {
  .checkbox-form .item .text {
    font-size: 14px !important;
    font-weight: 400;
  }
}
</style>
