<template>
  <div :class="this.disabled != undefined ? 'oye-timepicker disabled' : 'oye-timepicker'">
    <div class="p-1" >
      <select v-model="oyeHour" name="" id="" >
        <option v-for="n in getNumbers(this.start,this.end)" :value="n" :key="n">
          {{ ("0" + n).slice(-2) }}
        </option>
      </select>
    </div>
    <div class="p-1"><span>:</span></div>
    <div class="p-1">
      <select name="" v-model="oyeMin" id="">
        <option v-for="(i,n) in 60" :value="n" :key="n">
          {{ ("0" + n).slice(-2) }}
        </option>
      </select>
    </div>
    <div class="arrow-icon"></div>
  </div>
</template>
<script>
export default {
  name: "oyetimepicker",
  data: function () {
    return {
      oyeTime: this.value,
      oyeHour: "0",
      oyeMin: "0",
      oyePeriod: "",
    };
  },
  props: ["value","start", "end","disabled"],
  watch: {
    value: function (val) {
      if (val) {
        var onlyTime = val.split(" ")[0];
        this.oyeHour = parseInt(onlyTime.split(":")[0]);

        this.oyeMin = parseInt(onlyTime.split(":")[1]);
      } else {
        this.oyeTime = "00:00";
      }
    },
    oyeMin: function () {
      this.oyeTime = this.oyeHour + ":" + this.oyeMin + " " + this.oyePeriod;
      this.$emit("input", this.oyeTime);
    },
    oyeHour: function () {
      this.oyeTime = this.oyeHour + ":" + this.oyeMin + " " + this.oyePeriod;
      this.$emit("input", this.oyeTime);
    },
    oyePeriod: function () {
      this.$emit("input", this.oyeTime);
      this.oyeTime = this.oyeHour + ":" + this.oyeMin + " " + this.oyePeriod;
    },
  },
  mounted: function () {
    if (this.oyeTime) {
      var onlyTime = this.oyeTime.split(" ")[0];
      this.oyeHour = parseInt(onlyTime.split(":")[0]);
      this.oyeMin = parseInt(onlyTime.split(":")[1]);
    }
  },
  methods: {
    getNumbers:function(start,stop){
      // const arr = []
      // for(let i = 0; i < stop - start; i ++ ){
        
      //   arr.push(start + i);
      // }
      // console.log(start, stop, arr)
      // return arr;
      return new Array(parseInt(stop)-parseInt(start) ).fill(start).map((n,i)=> parseInt(n)+i);
      
      // return arr;
    }
  },
};
</script>