<template>
  <div class="schedule">
    <v-row class="status-bar">
      <div class="status-bar-content">
        <div v-for="(data, index) in status_bar.globalData" :key="index" class="sys-info">
          <span class="green-color2 status-info">{{ data.label }}&nbsp;&nbsp;</span>
          <span class="status-info">{{ data.value }}</span>
        </div>
        <div class="date-info">
          {{ status_bar.time }}
        </div>
      </div>
    </v-row>

    <div class="row content">
      <v-col cols="3" class="left-panel">
        <div :class="getTariffClass()">
          <div class="session-title">
            Electricity Tariff
            <button :class="getSeasonClass()">
              {{ getSeasonTitle() }}
            </button>
          </div>
          <div class="session-content">
            <div class="sesstion-detail" v-for="(data, index) in tariff" :key="index">
              <div style="width: 30%; text-align: ">
                <span> {{ data.label }}</span>
              </div>
              <div style="width: 30%; text-align: center">
                {{ data.value }} <span class="kwh">$/kWh</span>
              </div>
              <div style="width: 40%; text-align: right">
                <span>{{ data.start }} - {{ data.end }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="session date-picker">
          <div class="session-content">
            <v-date-picker
              ref="datepicker"
              flat
              no-title
              is-double-paned
              is-inline
              width="auto"
              class="date-picker"
              first-day-of-week="1"
              v-model="pickerDate"
              :picker-date.sync="pickerDate"
              type="date"
            ></v-date-picker>
          </div>
        </div>
        <div class="session no-border inside">
          <div class="session filter-schedule">
            <div class="session-title">Filter Scheduled Tasks</div>
            <div class="session-content">
              <div>
                <div>
                  <button block class="filter-button all-sys">All System</button>
                </div>
              </div>
              <div>
                <div>
                  <button block class="filter-button sub-sys">All sub-system</button>
                </div>
              </div>

              <checkbox
                v-model="checkedAnswers"
                :options="this.filters"
                @input="this.onFilter"
                selectAll="7"
              />
            </div>
          </div>
          <div class="add-btn">
            <button @click="onAddNewSchedule" class="add-btn">Add new schedule</button>
          </div>
        </div>
        <div class="session filter-schedule outside">
          <div class="session-title">Filter Scheduled Tasks</div>
          <div class="session-content">
            <div>
              <div>
                <button block class="filter-button all-sys">All System</button>
              </div>
            </div>
            <div>
              <div>
                <button block class="filter-button sub-sys">All sub-system</button>
              </div>
            </div>

            <checkbox
              v-model="checkedAnswers"
              :options="this.filters"
              @input="this.onFilter"
              selectAll="7"
            />
          </div>
        </div>
        <div class="session add-btn no-border outside">
          <button @click="onAddNewSchedule" class="add-btn">Add new schedule</button>
        </div>
      </v-col>
      <v-col cols="9" class="main-panel">
        <div class="calendar-header">
          <div class="calendar-type">
            <div v-for="data in supportCalendar" :key="data.key">
              <button
                block
                :class="calendarButtonClasses(data.key)"
                @click="changeView(data.key)"
              >
                {{ data.label }}
              </button>
            </div>
          </div>
          <div class="calendar-control">
            <div class="calendar-title v-date-picker-header theme--dark">
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <div class="v-date-picker-header__value">
                <span v-if="$refs.calendar" class="v-toolbar__title">
                  {{ $refs.calendar.title }}
                </span>
                <span v-if="!$refs.calendar" class="v-toolbar__title">
                  {{ current.format("MMMM YYYY") }}
                </span>
              </div>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <div class="calendar-body">
          <v-calendar
            ref="calendar"
            :class="getCalendarClasses"
            v-model="date"
            :weekdays="weekday"
            :type="type"
            :events="events"
            :event-overlap-threshold="30"
            @click:event="showEvent"
            :event-ripple="false"
            @change="calendarChange"
            :interval-format="formatEventTime"
            :interval-height="calcDailyHeight"
          >
            <template v-slot:event="{ event }" v-if="type == 'month'">
              <div>
                <div class="pl-1">
                  {{ event.name }}
                  <span class="time-info">{{ getEventMonthViewTime(event) }}</span>
                </div>
              </div>
            </template>
          </v-calendar>
          <v-menu
            ref="eventPopup"
            v-model="selectedOpen"
            :position-x="pop_pos.x"
            :position-y="pop_pos.y"
            absolute
            :close-on-content-click="false"
            max-width="350"
          >
            <v-card flat class="mini-event-detail">
              <div class="mini-event-arrow"></div>
              <div class="mini-event-content">
                <button
                  v-if="selectedEvent"
                  :class="
                    selectedEvent && selectedEvent.name
                      ? 'event-title ' + selectedEvent.name.toLowerCase()
                      : 'event-title'
                  "
                >
                  {{ selectedEvent.name }}
                </button>
                <button class="close-btn" @click="selectedOpen = false">X</button>
                <p class="event-detail-title" v-if="selectedEvent">Schedule Details:</p>
                <p
                  class="event-detail-info"
                  v-if="selectedEvent && selectedEvent.scheduleData"
                >
                  {{ showEventDetail() }}
                </p>

                <v-row class="time-from">
                  <v-col cols="12">
                    <v-row>
                      <div class="date">
                        <div class="time-title">FROM</div>
                        <v-menu
                          v-model="menuFrom"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          disabled
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="startDateText"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateFrom"
                            no-title
                            @input="menuFrom = false"
                          ></v-date-picker>
                        </v-menu>
                        <v-btn :ripple="false" icon class="icon-calendar"></v-btn>
                      </div>

                      <div class="time">
                        <oyetimepicker
                          disabled
                          v-model="timeFrom"
                          start="0"
                          end="24"
                        ></oyetimepicker>
                      </div>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="time-to">
                  <v-col cols="12">
                    <v-row>
                      <div class="date">
                        <div class="time-title">TO</div>
                        <v-menu
                          v-model="menuTo"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          disabled
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="endDateText"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateTo"
                            no-title
                            @input="menuTo = false"
                          ></v-date-picker>
                        </v-menu>
                        <v-btn :ripple="false" icon class="icon-calendar"></v-btn>
                      </div>

                      <div class="time">
                        <oyetimepicker
                          v-model="timeTo"
                          start="0"
                          end="24"
                          disabled
                        ></oyetimepicker>
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
                <div class="event-footer">
                  <button
                    block
                    @click="confirmDelete"
                    class="mini-cart-button delete"
                    v-if="selectedEvent"
                  >
                    DELETE
                  </button>
                  <button
                    block
                    @click="reset"
                    class="mini-cart-button cancel"
                    v-if="selectedEvent == null"
                  >
                    CANCEL
                  </button>
                  <button
                    block
                    @click="changeDialogType(2)"
                    class="mini-cart-button update"
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            </v-card>
          </v-menu>
          <v-col cols="3" class="event-detail" v-if="drawer">
            <div class="event-content">
              <button
                v-if="selectedEvent"
                :class="
                  selectedEvent && selectedEvent.name
                    ? 'event-title ' + selectedEvent.name.toLowerCase()
                    : 'event-title'
                "
              >
                {{ selectedEvent.name }}
              </button>
              <button class="close-btn" @click="drawer = false">X</button>
              <div
                class="event-location"
                v-if="
                  selectedEvent &&
                  selectedEvent.scheduleData &&
                  selectedEvent.scheduleData.region
                "
              >
                <div class="location"></div>
                <span class="location-detail">{{
                  selectedEvent.scheduleData.region.name
                }}</span>
              </div>

              <p class="event-detail-title" v-if="selectedEvent">Schedule Details:</p>
              <p
                class="event-detail-info"
                v-if="selectedEvent && selectedEvent.scheduleData"
              >
                {{ showEventDetail() }}
              </p>

              <v-row class="time-from">
                <v-col cols="12" class="time-title">START TIME</v-col>
                <v-col cols="12">
                  <v-row>
                    <div class="date">
                      <v-menu
                        v-model="menuFrom"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        disabled
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="startDateText"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="dateFrom"
                          no-title
                          @input="menuFrom = false"
                        ></v-date-picker>
                      </v-menu>
                      <v-btn :ripple="false" icon class="icon-calendar"></v-btn>
                    </div>

                    <div class="time">
                      <oyetimepicker
                        v-model="timeFrom"
                        start="0"
                        end="24"
                        disabled
                      ></oyetimepicker>
                    </div>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="time-to">
                <v-col cols="12" class="time-title">END TIME</v-col>
                <v-col cols="12">
                  <v-row>
                    <div class="date">
                      <v-menu
                        v-model="menuTo"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        disabled
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="endDateText"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="dateTo"
                          no-title
                          @input="menuTo = false"
                        ></v-date-picker>
                      </v-menu>
                      <v-btn :ripple="false" icon class="icon-calendar"></v-btn>
                    </div>

                    <div class="time">
                      <oyetimepicker
                        v-model="timeTo"
                        start="0"
                        end="24"
                        disabled
                      ></oyetimepicker>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <div class="event-footer">
              <button
                block
                @click="confirmDelete"
                class="mini-cart-button delete"
                v-if="selectedEvent"
              >
                DELETE
              </button>
              <button
                block
                @click="drawer = false"
                class="mini-cart-button cancel"
                v-if="selectedEvent == null"
              >
                CANCEL
              </button>
              <button block @click="changeDialogType(2)" class="mini-cart-button update">
                UPDATE
              </button>
            </div>
          </v-col>

          <v-dialog v-model="dialog" persistent :max-width="dialogType == 4 ? 720 : 1094">
            <v-card class="add-schedule-popup">
              <v-card-title>
                <span v-if="dialogType == 1"> ADD NEW SCHEDULE</span>
                <span v-if="dialogType == 2"> UPDATE NEW SCHEDULE</span>
                <span v-if="dialogType == 4"> DELETE THE SCHEDULE</span>
              </v-card-title>

              <v-card-text>
                <v-container v-if="dialogType != 4">
                  <v-row>
                    <label class="introduction">Choose Sites and Systems</label>
                  </v-row>
                  <v-row class="options-container">
                    <v-col cols="4" class="options application">
                      <v-row class="options-title"><span>Choose application</span></v-row>
                      <v-row class="options-content">
                        <v-treeview
                          item-disabled="locked"
                          :items="newSchedule.application"
                          item-children="children"
                          v-model="scheduleApp"
                          open-all
                          activatable
                          return-object
                          :active="scheduleApp"
                          @update:active="chooseApplication"
                        ></v-treeview>
                      </v-row>
                    </v-col>
                    <v-col cols="4" class="options region">
                      <v-row class="options-title"><span>Choose region</span></v-row>
                      <v-row class="options-content">
                        <v-list-item-group v-model="scheduleRegion">
                          <v-list-item v-for="(region, i) in newSchedule.region" :key="i">
                            <v-list-item-content>
                              <v-list-item-title
                                class="options-child"
                                v-text="region.name"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                        <!-- </v-list> -->
                      </v-row>
                    </v-col>
                    <v-col cols="4" class="options sites">
                      <v-row class="options-title"
                        ><span>Choose sites & system</span></v-row
                      >
                      <v-row class="options-content">
                        <v-treeview
                          selectable
                          item-disabled="locked"
                          selected-color="black"
                          :items="newSchedule.sites"
                          item-children="sys"
                          v-model="scheduleSite"
                          open-all
                          return-object
                        ></v-treeview>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="shedule-datetime">
                    <v-col cols="6">
                      <v-row>
                        <v-col cols="12"
                          ><v-row class="datetime-title">START TIME</v-row></v-col
                        >
                        <v-col cols="12">
                          <v-row>
                            <div class="date">
                              <v-menu
                                v-model="menuFrom_dialog"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    :value="startDateText"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  class="add-schedule-date"
                                  v-model="dateFrom"
                                  no-title
                                  @input="menuFrom_dialog = false"
                                ></v-date-picker>
                              </v-menu>
                              <v-btn :ripple="false" icon class="icon-calendar"></v-btn>
                            </div>

                            <div class="time">
                              <oyetimepicker
                                v-model="timeFrom"
                                start="0"
                                end="24"
                              ></oyetimepicker>
                            </div>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6" style="padding-left: 36px">
                      <v-row>
                        <v-col cols="12"
                          ><v-row class="datetime-title">END TIME</v-row></v-col
                        >
                        <v-col cols="12">
                          <v-row>
                            <div class="date">
                              <v-menu
                                v-model="menuTo_dialog"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    :value="endDateText"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  class="add-schedule-date"
                                  v-model="dateTo"
                                  no-title
                                  @input="menuTo_dialog = false"
                                ></v-date-picker>
                              </v-menu>
                              <v-btn :ripple="false" icon class="icon-calendar"></v-btn>
                            </div>

                            <div class="time">
                              <oyetimepicker
                                v-model="timeTo"
                                start="0"
                                end="24"
                              ></oyetimepicker></div
                          ></v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container v-if="dialogType == 4">
                  <v-row>
                    <label class="warning-text"
                      >Are you sure to delete the following schedule?</label
                    >
                  </v-row>
                  <!-- {{selectedEvent}} -->
                  <v-row>
                    <div class="border-line"></div>
                  </v-row>
                  <v-row>
                    <label
                      class="event-detail-info"
                      v-if="selectedEvent && selectedEvent.scheduleData"
                    >
                      {{ showEventDetail() }}
                    </label>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions :class="dialogType == 4 ? 'delete-bar' : ''">
                <v-spacer></v-spacer>

                <button class="btn btn-cancel" @click="cancelAction">Cancel</button>
                <button
                  class="btn btn-add"
                  :disabled="!valid"
                  v-if="dialogType == 1"
                  @click="updateData"
                >
                  add
                </button>
                <button
                  class="btn btn-update"
                  :disabled="!valid"
                  v-if="dialogType == 2"
                  @click="updateData"
                >
                  update
                </button>
                <button class="btn btn-delete" v-if="dialogType == 4" @click="deleteData">
                  Delete
                </button>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
    </div>
    <v-dialog
      v-model="messageDialog"
      max-width="700"
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm">
        <v-card-title>{{ apiTitle }}</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" md="9" style="padding-top: 6px">
              <div v-html="apiMessage"></div>
            </v-col>
            <v-col cols="12" md="3" class="text-right">
              <v-btn class="form-btn" :ripple="false" @click="closeMessage">OK</v-btn>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" src="../../assets/css/schedule.scss"></style>

<script>
import moment from "moment";
import oyetimepicker from "../../components/TimePicker.vue";
import checkbox from "../../components/CheckBox.vue";
export default {
  name: "ScheduleManagement",
  components: {
    oyetimepicker,
    checkbox,
  },
  data: () => ({
    status_bar: {
      globalData: [
        { label: "SYSTEM ONLINE", value: "888/2122 Online" },
        { label: "TOTAL PV GENERATED", value: "7.10 kWh(Today)" },
        { label: "TOTAL BATTERY CAPACITY", value: "6.24/6.24 kWh" },
      ],
      region: "1234",
      site: "all sites",
      time: null,
      interval: null,
    },

    checkedAnswers: ["1", "2", "4", "7"],
    filters: [
      { key: "7", class: "cb-all", label: "All" },
      { key: "1", class: "cb-scheduled", label: "Scheduled" },
      { key: "2", class: "cb-in-progress", label: "In-Progress" },
      { key: "4", class: "cb-completed", label: "Completed" },
    ],
    tariff: [
      {
        label: "Off-peak",
        value: "4.132",
        start: "12am",
        end: "3:50am",
      },
      {
        label: "Mid-peak",
        value: "5.108",
        start: "4am",
        end: "10:50am",
      },
      {
        label: "High-peak",
        value: "8.323",
        start: "11am",
        end: "11:50am",
      },
    ],
    type: "month",
    supportCalendar: [
      { key: "day", label: "Daily" },
      { key: "week", label: "Weekly" },
      { key: "month", label: "Monthly" },
    ],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    originEvents: [],
    events: [
      {
        type: 4,
        typeColor: "completed",
        name: "Completed",
        start: moment("2021/08/15 10:30:00").format("YYYY-MM-DD HH:mm:ss"),
        end: moment("2021/08/15 13:30:00").format("YYYY-MM-DD HH:mm:ss"),
        color: "completed",
        scheduleData: {
          app: { name: "demand response", id: 11 },
          region: { name: "Newfoundland", id: "region_2" },
          site: {
            name: "Site 00001",
            id: 1,
            sys: [
              { name: "ESS", id: 11 },
              { name: "EV", id: 12 },
            ],
          },
        },
      },
      {
        type: 4,
        typeColor: "completed",
        name: "Completed",
        start: moment("2021/08/13 14:30:00").format("YYYY-MM-DD HH:mm:ss"),
        end: moment("2021/08/13 16:30:00").format("YYYY-MM-DD HH:mm:ss"),
        color: "completed",
        scheduleData: {
          app: { name: "demand response", id: 11 },
          region: { name: "Newfoundland", id: "region_2" },
          site: {
            name: "Site 00001",
            id: 1,
            sys: [
              { name: "ESS", id: 11 },
              { name: "EV", id: 12 },
            ],
          },
        },
      },
    ],
    colors: ["scheduled", "in-progress", "completed", "deleted"],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
    filter: [],
    globalData: [
      { label: "SYSTEM ONLINE", value: "888/2122 Online" },
      { label: "TOTAL PV GENERATED", value: "7.10 kWh(Today)" },
      { label: "TOTAL BATTERY CAPACITY", value: "6.24/6.24 kWh" },
    ],
    selectedEvent: null,
    selectedElement: null,
    selectedOpen: false,
    dateFrom: null,
    dateTo: null,
    timeFrom: null,
    timeTo: null,
    menuFrom: false,
    menuTo: false,
    menuFrom_dialog: false,
    menuTo_dialog: false,
    hours: {
      open_time: null,
    },
    drawer: false,
    current: moment(),
    season: "summer",
    dialog: false,
    dialogType: 1, //add 1, update 2, delete 4
    date: moment().format("YYYY-MM-DD"),
    pop_pos: {
      x: 0,
      y: 0,
    },
    scheduleSite: [],
    scheduleApp: null,
    scheduleRegion: null,
    newSchedule: {
      application: [
        {
          id: 1,
          name: "Grid Application",
          children: [
            { name: "demand response", id: 11 },
            { name: "Frequency redulation", id: 12 },
            { name: "Voltage Control", id: 13 },
          ],
        },
        {
          id: 2,
          name: "btm application",
          children: [{ name: "peak shaving", id: 22 }],
        },
      ],
      region: [
        { name: "Manitoba", id: "region_1" },
        { name: "Newfoundland", id: "region_2" },
        { name: "new brunswick", id: "region_3" },
        { name: "York State", id: "region_4" },
        { name: "new york", id: "region_5" },
        { name: "San Francisco", id: "region_6" },
      ],
      sites: [
        {
          id: 1,
          name: "Site 00001",

          sys: [
            { name: "ESS", id: 11 },
            { name: "EV", id: 12 },
          ],
        },
        {
          id: 2,
          name: "Site 00002",
          sys: [
            { name: "Solar", id: 21 },
            { name: "Waste to Energy", id: 22 },
          ],
        },
        {
          id: 3,
          name: "Site 00003",
          sys: [
            { name: "Solar", id: 31 },
            { name: "Waste to Energy", id: 32 },
          ],
        },
      ],
    },
    messageDialog: false,
    apiMessage: "",
    apiTitle: "",
  }),
  methods: {
    closeMessage() {
      this.messageDialog = false;
    },
    changeView(view) {
      if (this.type != view) {
        this.type = view;
        if (this.type == "month") {
          this.weekday = [0, 1, 2, 3, 4, 5, 6];
        } else {
          this.weekday = [1, 2, 3, 4, 5, 6, 0];
        }
        this.reset();
      }
    },
    reset() {
      this.dialog = false;
      this.selectedOpen = false;
      this.drawer = false;
      this.selectedEvent = null;
      this.dateFrom = moment().format("YYYY-MM-DD");
      this.dateTo = moment().format("YYYY-MM-DD");
      this.timeFrom = "09:00";
      this.timeTo = "10:00";
      this.scheduleApp = [];
      this.scheduleRegion = null;
      this.scheduleSite = [];
    },
    onAddNewSchedule() {
      this.reset();
      this.changeDialogType(1);
    },
    findRegion(arr, item) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === item.id) {
          return i;
        }
      }
      return 0;
    },
    prepareDataForDialog() {
      if (this.selectedEvent && this.selectedEvent.type) {
        const { scheduleData } = this.selectedEvent;
        if (scheduleData) {
          const { app, site, region } = scheduleData;
          this.scheduleApp = [app];
          if (region) {
            const index = this.findRegion(this.newSchedule.region, region);
            this.scheduleRegion = index;
          }
          if (site) {
            this.scheduleSite = site.sys || [];
          }
        }
      } else {
        this.dateFrom = moment().format("YYYY-MM-DD");
        this.dateTo = moment().format("YYYY-MM-DD");
        this.timeFrom = "09:00";
        this.timeTo = "10:00";
        this.scheduleApp = [];
        this.scheduleRegion = null;
        this.scheduleSite = [];
      }
    },
    changeDialogType(type) {
      this.dialogType = type;

      if (type == 0) {
        this.dialog = false;
      } else if (type == 4) {
        // this.prepareDataForDialog();
        this.dialog = true;
      } else {
        this.drawer = false;
        this.selectedOpen = false;
        this.prepareDataForDialog();
        this.dialog = true;

        const interval_check = setInterval(() => {
          const els = document.getElementsByClassName("v-dialog--persistent");
          if (els && els.length) {
            clearInterval(interval_check);

            els[0].addEventListener("click", (e) => {
              console.log(e.target);
              this.menuFrom_dialog = false;
              this.menuTo_dialog = false;
            });
          }
        }, 1000);
      }
    },
    onFilter(selected) {
      this.events.forEach((event) => {
        if (selected & event.type) {
          event.color = event.typeColor;
        } else {
          event.color = event.typeColor + " disable";
        }
      });
    },
    calendarButtonClasses: function (current) {
      return current == this.type ? "calendar-type-btn active" : "calendar-type-btn";
    },

    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    showPopup(el) {
      this.drawer = false;
      const open = () => {
        requestAnimationFrame(() =>
          requestAnimationFrame(() => {
            this.pop_pos = this.calcPopupPosition(el);
            this.selectedOpen = true;
          })
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
    },
    showSlider() {
      this.selectedOpen = false;
      const open = () => {
        requestAnimationFrame(() =>
          requestAnimationFrame(() => {
            this.drawer = true;
          })
        );
      };

      if (this.drawer) {
        this.drawer = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
    },
    showEvent({ nativeEvent, event }) {
      nativeEvent.stopPropagation();
      nativeEvent.preventDefault();
      nativeEvent.stopImmediatePropagation();
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      this.dateFrom = moment(event.start).format("YYYY-MM-DD");
      this.timeFrom = moment(event.start).format("HH:mm");
      this.dateTo = moment(event.end).format("YYYY-MM-DD");
      this.timeTo = moment(event.end).format("HH:mm");
      switch (this.type) {
        case "day":
          this.showSlider();
          break;
        case "week":
        case "month":
        default:
          this.showPopup(nativeEvent.target);
      }
    },
    calcPopupPosition(el) {
      const w = 366;
      const h = 317;
      if (!el) {
        return {
          x: (window.innerWidth - w) / 2,
          y: (window.innerHeight - h) / 2,
        };
      }

      while (!el.nodeName === "BODY") {
        if (
          el.classList.contains("v-event-timed") ||
          el.classList.contains("v-event-start")
        ) {
          break;
        } else {
          el = el.parentElement;
        }
      }

      if (el.nodeName === "BODY") {
        return {
          x: (window.innerWidth - w) / 2,
          y: (window.innerHeight - h) / 2,
        };
      }

      const { top, left, width, height } = el.getBoundingClientRect();

      const pos = {
        x: left + width,
        y: top + height / 2 - h / 2,
      };
      if (pos.x > window.innerWidth) {
        pos.x = left;
      }

      if (pos.y > window.innerHeight) {
        pos.y = top;
      }
      return pos;
    },
    updateRange({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }

      this.events = events;
    },
    updateEventTime(day, time) {
      const hour = time.split(":")[0];
      const minute = time.split(":")[1];
      let newTime = moment(day, "YYYY-MM-DD HH:mm");
      newTime.set({ hour, minute, second: 0, millisecond: 0 });
      return newTime.format("YYYY-MM-DD HH:mm:ss");
    },
    findParentSys(item, mainArr) {
      let parent = null;
      for (let i = 0; i < mainArr.length; i++) {
        parent = mainArr[i];
        if (parent.sys) {
          for (let j = 0; j < parent.sys.length; j++) {
            if (parent.sys[j].id === item.id) {
              return {
                id: parent.id,
                name: parent.name,
              };
            }
          }
        }
      }
      return null;
    },
    updateData() {
      try {
        const region = this.newSchedule.region[this.scheduleRegion];

        if (this.selectedEvent == null) {
          this.selectedEvent = {
            type: 1,
            name: "Scheduled",
            typeColor: "scheduled",
            color: "scheduled",
          };
          this.events.push(this.selectedEvent);
        }

        this.selectedEvent.start = this.updateEventTime(this.dateFrom, this.timeFrom);
        this.selectedEvent.end = this.updateEventTime(this.dateTo, this.timeTo);

        if (!this.selectedEvent.scheduleData) {
          this.selectedEvent.scheduleData = {};
        }

        if (this.scheduleApp && this.scheduleSite[0]) {
          this.selectedEvent.scheduleData.app = this.scheduleApp[0];
        }
        if (this.scheduleSite && this.scheduleSite[0]) {
          const site = this.findParentSys(this.scheduleSite[0], this.newSchedule.sites);
          this.selectedEvent.scheduleData.site = {
            id: site.id,
            name: site.name,
            sys: this.scheduleSite,
          };
        }
        if (region) {
          this.selectedEvent.scheduleData.region = region;
        }
        this.events = [...this.events];

        this.reset();
        this.showMessage();
      } catch (e) {
        console.log(e);
      }
    },
    confirmDelete() {
      this.selectedOpen = false;
      this.drawer = false;
      this.changeDialogType(4);
    },
    cancelAction() {
      this.dialog = false;
      this.reset();
    },
    deleteData() {
      if (this.selectedEvent && this.selectedEvent.type) {
        this.selectedEvent.color = "deleted";
        this.selectedEvent.name = "Deleted";
        this.selectedEvent.typeColor = "deleted";
        this.selectedEvent.type = 1;
      }
      this.reset();
      this.showMessage();
    },
    changeSeason() {
      if (this.season === "summer") {
        this.season = "winter";
      } else {
        this.season = "summer";
      }
    },
    getSeasonTitle() {
      if (this.season === "summer") {
        return "summer time";
      } else {
        return "winter time";
      }
    },
    getSeasonClass() {
      if (this.season === "summer") {
        return "season summer";
      } else {
        return "season winter";
      }
    },
    getTariffClass() {
      if (this.season === "summer") {
        return "session electricity summer";
      } else {
        return "session electricity winter";
      }
    },
    calendarChange(data) {
      if ([1, 2, 3, 4, 5, 6].includes(data.start.month)) {
        this.season = "summer";
      } else if ([7, 8, 9, 10, 11, 12].includes(data.start.month)) {
        this.season = "winter";
      }
    },
    chooseApplication(data) {
      this.scheduleApp = data;
    },
    showEventDetail() {
      const results = [];
      if (this.selectedEvent && this.selectedEvent.scheduleData) {
        const { app, region, site } = this.selectedEvent.scheduleData;

        if (app) {
          results.push(app.name);
        }
        if (region) {
          results.push(region.name);
        }

        if (site) {
          results.push(site.name);
          if (site.sys) {
            const arr = [];
            site.sys.forEach((system) => {
              arr.push(system.name);
            });
            results.push(arr.join(", "));
          }
        }
      }
      return results.join(" - ");
    },
    getEventMonthViewTime(event) {
      const arr = [];
      arr.push(moment(event.start).format("HH"));
      arr.push(moment(event.end).format("HH"));
      return arr.join("-");
    },
    formatEventTime(date) {
      return date.time;
    },
    showMessage() {
      let message = "";
      switch (this.dialogType) {
        case 1:
          message = "Schedule item has been successfully created";
          break;
        case 2:
          message = "Schedule item has been successfully updated";
          break;
        case 4:
          message = "Schedule item has been successfully deleted";
          break;
        default:
          message = "Schedule has been successfully updated";
          break;
      }
      this.apiMessage = message;
      this.apiTitle = "Success";
      this.messageDialog = true;
    },
  },
  computed: {
    calcDailyHeight() {
      // console.log((window.innerHeight * 4) / 100);
      return (window.innerHeight * 4) / 100;
    },
    startDateText() {
      // console.log(this.timeFrom);
      return this.dateFrom ? moment(this.dateFrom).format("DD MMM YYYY") : "";
    },
    endDateText() {
      return this.dateTo ? moment(this.dateTo).format("DD MMM YYYY") : "";
    },
    getCalendarClasses: function () {
      let classes = "content col ";
      if (this.drawer) {
        classes += "col-9";
      } else {
        classes += "col-12";
      }
      return classes;
    },
    pickerDate: {
      get: function () {
        return this.date;
      },
      set: function (newDate) {
        this.date = moment(newDate).format("YYYY-MM-DD");
      },
    },
    valid: function () {
      return this.scheduleApp?.length && this.scheduleRegion && this.scheduleSite?.length;
    },
  },
  created() {
    this.interval = setInterval(() => {
      this.status_bar.time = moment().format("ddd D MMM YYYY   HH:mm");
    }, 1000);
  },
};
</script>
